import React, { useState, useEffect } from "react";
import { Modal, Alert } from "react-bootstrap";

const FormSuccessModal = ({ text, formSuccess }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (formSuccess) {
      setShow(true);
    }
  }, [formSuccess]);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        style={{
          zIndex: 1060,
          position: "fixed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "10px auto 0",
        }}
      >
        <Alert
          variant="success"
          dismissible
          style={{
            width: "100%",
            margin: "auto",
          }}
        >
          <Alert.Heading>Success!</Alert.Heading>
          <p>{text}</p>
        </Alert>
      </Modal>
    </>
  );
};

export default FormSuccessModal;
