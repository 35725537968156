import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

const Navigationbar = () => {
  const authContext = useContext(AuthContext);

  // Check if the user is an admin
  const isAdminUser = authContext.authState.userInfo.role === 'admin';

  return (
    <Navbar bg="dark" expand="lg">
      <Container fluid>
        <Navbar.Brand style={{ color: "white" }}>
          Flair Invoice Portal
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/send-EDI" style={{ color: "white" }}>
              Send EDI
            </Nav.Link>
            <Nav.Link href="/entity-management" style={{ color: "white" }}>
              Entity Management
            </Nav.Link>
            <Nav.Link href="/history" style={{ color: "white" }}>
              EDI History
            </Nav.Link>
            { isAdminUser &&
              <Nav.Link href="/settings" style={{color: "white"}}>
                Settings
              </Nav.Link>
            }   
          </Nav>
          <Nav className="ml-auto">
            <Nav.Link style={{ color: "white" }} onClick={authContext.logout}>
              Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigationbar;
