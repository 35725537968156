import React from "react";

const FourOFour = () => {
  return (
    <>
      <h1>No Content: 404</h1>
    </>
  );
};

export default FourOFour;
