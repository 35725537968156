/**
 * File: fetchFormatter.js
 * Author: Hwanyi (Jacob) Lee
 * Date: 2023-03-09
 * Description: This file contains a function for converting user input to MongoDB specific format.
 * @param {object} source - The user input.
 * @returns {object} - The object in Invoice model format.
 */

// Convert input amount to cent to keep the numbers as integer.
const usdToCents = (amount) => {
  // Remove any commas from the string and split into dollar and cent parts
  const parts = amount.replace(",", "").split(".");

  // Convert the dollar part to cents
  let cents = parseInt(parts[0]) * 100;

  // If there is a cent part, add it to the cents value
  if (parts.length > 1) {
    cents += parseInt(parts[1].slice(0,2).padEnd(2, "0"));
  }

  return cents;
};

// Convert string type charge to an array with objects
// Source = Charges
// Team = Name of team
const chargeFormatter = (source, opusInstanceCountry) => {

  // Return array
  let resultArr = [];

  // Remove the last "\n".
  // When the charges are copied, they it adds "\n" at the end.
  const charges = source.slice(0, -1).split("\n");

  console.log("charges", charges);

  // For each line.
  for (const charge of charges) {
    let freightCode, freightName, pc, unit, typeOrSize, rate, volume, amount; // Common columns
    let rateCurrency, VATPercentage, VATCode, VATPercentageNegative, accountReceivableCurrency, accountReceivableExDate, accountReceivableExRate, accountReceivableTotalAmount; // OBMX OPUS Specific 
    console.log("charge", charge);

    if (opusInstanceCountry === "US") {
      [
        freightCode,
        freightName,
        pc,
        unit,
        typeOrSize,
        rate,
        volume,
        amount,
      ] = charge.split("\t");

    }

    else if (opusInstanceCountry === "MX") {
      [
        freightCode,
        freightName,
        pc,
        unit,
        typeOrSize,
        rateCurrency,
        VATPercentage,
        VATCode,
        VATPercentageNegative,
        rate,
        volume,
        amount,
        accountReceivableCurrency,
        accountReceivableExDate,
        accountReceivableExRate,
        accountReceivableTotalAmount
      ] = charge.split("\t");
    }

    // Build an object.
    const item = {
      freightCode,
      freightName,
      pc,
      unit,
      typeOrSize,
      rate: usdToCents(rate, opusInstanceCountry),
      volume,
      amount: usdToCents(amount, opusInstanceCountry),

    }
    resultArr.push(item);
  }

  return resultArr;
};

// PO Numbers are in a string separated by commas
const poNoFormatter = (source) => {
  return source.split(",");
};



// This function formats the user-input data into the right format
export const fetchFormatter = (source, opusInstanceCountry) => {

  // Copy the data in source to target
  let { ...target } = source;

  // Properties to delete from target because they are in different format.
  const propsToDelete = [
    "charges",
    "ladingQuantity",
    "packingForm",
    "poNos",
    "placeOfDeliveryDate",
    "placeOfDeliveryName",
    "placeOfReceiptDate",
    "placeOfReceiptName",
    "portOfDischargeDate",
    "portOfDischargeName",
    "portOfServicingDate",
    "portOfServicingName",
    "shipperOrigin",
    "shipTo",
    "consignee",
    "volume",
    "weight",
    "netAmount",
  ];

  // Delete props in propsToDelete from target
  propsToDelete.forEach((prop) => {
    delete target[prop];
  });

  // Get items from source.
  const {
    charges,
    ladingQuantity,
    packingForm,
    poNos,
    placeOfDeliveryDate,
    placeOfDeliveryName,
    placeOfReceiptDate,
    placeOfReceiptName,
    portOfDischargeDate,
    portOfDischargeName,
    portOfServicingDate,
    portOfServicingName,
    shipperOrigin,
    shipTo,
    consignee,
    volume,
    weight,
    netAmount,
  } = source;

  // Restructure target object.
  target = {
    ...target,
    packageInfo: { ladingQuantity, packingForm },
    portOfDischarge: { name: portOfDischargeName.replace(/ /g, ''), date: portOfDischargeDate },
    placeOfDelivery: { name: placeOfDeliveryName.replace(/ /g, ''), date: placeOfDeliveryDate },
    portOfServicing: { name: portOfServicingName.replace(/ /g, ''), date: portOfServicingDate },
    placeOfReceipt:
      placeOfReceiptName && placeOfReceiptDate
        ? { name: placeOfReceiptName.replace(/ /g, ''), date: placeOfReceiptDate }
        : { name: portOfServicingName.replace(/ /g, ''), date: portOfServicingDate },
    charges: chargeFormatter(charges, opusInstanceCountry),
    poNos: poNoFormatter(poNos),
    shipperOrigin: JSON.parse(shipperOrigin),
    shipTo: JSON.parse(shipTo),
    consignee: JSON.parse(consignee),
    volume: parseFloat(volume.replace(/,/g, "")),
    weight: parseFloat(weight.replace(/,/g, "")),
    netAmount: usdToCents(netAmount),
  };

  return target;
};
