import React, { useState, createContext, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FetchContext } from "./FetchContext";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const fetchContext = useContext(FetchContext);

  const [authState, setAuthState] = useState({
    userInfo: null,
    isAuthenticated: false,
  });

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const { data } = await fetchContext.authAxios.get(
          "/api/users/user-info"
        );
        /*
          Sample response from /api/users/user-info:

          {
            "message": "Authorization Successful",
            "userInfo": {
              "_id": "63fe53d645cc96dbc29c226e",
              "name": "Jacob",
              "email": "jacob@oceanbluexp.com",
              "role": "admin"
            }
          }
        */
        setAuthState({
          userInfo: data.user,
          isAuthenticated: true,
        });
      } catch (error) {
        setAuthState({
          userInfo: {},
          isAuthenticated: false,
        });
      }
    };
    getUserInfo();
  }, [fetchContext]);

  const setAuthInfo = ({ userInfo }) => {
    setAuthState({
      userInfo,
      isAuthenticated: userInfo && userInfo._id ? true : false,
    });
  };

  const logout = async () => {
    try {
      await fetchContext.authAxios.post("/api/users/logout");
      setAuthInfo({
        userInfo: {},
        isAuthenticated: false,
      });
      navigate.push("/login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        logout,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
