import React, { createContext, useEffect } from "react";
import axios from "axios";

const FetchContext = createContext();
const { Provider } = FetchContext;

const FetchProvider = ({ children }) => {
  const publicAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
  });
  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
  });

  useEffect(() => {
    const getCSRFToken = async () => {
      try {
        const { data } = await publicAxios.get("/api/users/csrf-token");
        publicAxios.defaults.headers["X-CSRF-Token"] = data.csrfToken;
        authAxios.defaults.headers["X-CSRF-Token"] = data.csrfToken;
      } catch (error) {
        console.log(error);
      }
    };
    getCSRFToken();
  }, []);

  return <Provider value={{ publicAxios, authAxios }}>{children}</Provider>;
};

export { FetchContext, FetchProvider };
