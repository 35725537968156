import React from "react";

const Settings = () => {
    return (
        <>
            <h1>Settings</h1>
        </>
    )
}

export default Settings;