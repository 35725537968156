import React, { useState, useRef, useContext } from "react";
import { Button, Form, Modal, Alert } from "react-bootstrap";
import FormErrorModal from "./FormErrorModal";
import FormSuccessModal from "./FormSuccessModal";
import { FetchContext } from "../contexts/FetchContext";

const EntityModal = (props) => {
  const [formSuccess, setFormSuccess] = useState();
  const [formError, setFormError] = useState();

  const fetchContext = useContext(FetchContext);

  const formRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const {
      name: { value: name },
      address: { value: address },
      city: { value: city },
      state: { value: state },
      country: { value: country },
      postalCode: { value: postalCode },
      code: { value: code },
    } = event.target;

    const inputValue = {
      name,
      address,
      city,
      state,
      country,
      postalCode,
      code,
    };
    try {
      const response = await fetchContext.authAxios.post(
        "/api/entities",
        inputValue,
        { headers: { "content-Type": "application/json" } }
      );
      if (response.status < 200 || response > 299) {
        throw Error(`Could not create an entity`);
      }
      const { _id } = response.data.entity._doc;
      props.onDataChange([...props.currentItems, { _id, ...inputValue }]);
      setFormSuccess(true);
      setFormError(false);
      formRef.current.reset();
    } catch (error) {
      setFormError(true);
      setFormSuccess(false);
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        show={props.state}
        onHide={props.onClose}
        style={{ padding: "0px !important" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>New Entity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formSuccess && (
            <FormSuccessModal text="Entity is successfully saved." />
          )}
          {formError && <FormErrorModal text="Entity is not saved." />}
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="code">
              <Form.Label>Entity Code</Form.Label>
              <Form.Control type="text" placeholder="Entity Code" autoFocus />
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Entity Name" autoFocus />
            </Form.Group>
            <Form.Group className="mb-3" controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entity Address"
                autoFocus
                maxLength="55"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entity Address"
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="state">
              <Form.Label>State / Province</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entity State / Province. Ex) CA"
                autoFocus
                maxLength="2"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="country">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entity Country. Ex) US"
                autoFocus
                maxLength="2"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="postalCode">
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entity Postal Code"
                autoFocus
              />
            </Form.Group>
            <hr />
            <Form.Group className="d-flex justify-content-end">
              <Button variant="secondary" onClick={props.onClose}>
                Close
              </Button>
              <Button
                variant="primary"
                type="submit"
                style={{ marginLeft: "5px" }}
              >
                Save Entity
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EntityModal;
