import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import { AgGridReact } from "ag-grid-react";
import { Form, Button } from "react-bootstrap";
import EntityModal from "../components/EntityModal";
import { FetchContext } from "../contexts/FetchContext";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const EntityManagement = () => {
  const [items, setItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [filterText, setFilterText] = useState("");

  const fetchContext = useContext(FetchContext);

  const gridRef = useRef();

  useEffect(() => {
    const fetchItems = async () => {
      const { data } = await fetchContext.authAxios("/api/entities");
      setItems(data);
    };
    fetchItems();
  }, []);

  const [columnDefs, setColumnDefs] = useState([
    { field: "code" },
    { field: "name" },
    { field: "address" },
    { field: "city" },
    { field: "state" },
    { field: "country" },
    { field: "postalCode" },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    editable: true,
  }));

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };

  const updateRow = async (params) => {
    const {
      data: { _id: id },
      newValue,
      oldValue,
      colDef: { field: dataField },
    } = params;

    try {
      const response = await fetchContext.authAxios.put(
        `/api/entities/${id}`,
        {
          dataField,
          oldValue,
          newValue,
        },
        {
          headers: { "content-Type": "application/json" },
        }
      );
      if (response.status < 200 || response > 299) {
        throw new Error(`Could not update row with ID: ${id}`);
      }
    } catch (error) {
      console.log(`Could not update an entity: ${error}`);
    }
  };

  const onCellValueChanged = async (params) => {
    await updateRow(params);
  };

  const deleteRowFromBackend = async (id) => {
    try {
      const response = await fetchContext.authAxios.delete(
        `/api/entities/${id}`
      );
      if (response.status < 200 || response > 299) {
        throw new Error(`Could not delete row with ID: ${id}`);
      }
    } catch (error) {
      console.log(`Could not delete an entity: ${error}`);
    }
  };

  const deleteSelectedRows = async () => {
    const selectedNodes = gridRef.current.getSelectedNodes();
    const selectedIds = selectedNodes.map((node) => node.data._id);

    try {
      const deletePromises = selectedIds.map((id) => deleteRowFromBackend(id));
      await Promise.all(deletePromises);

      const newItems = items.filter((row) => !selectedIds.includes(row._id));
      setItems(newItems);
    } catch (error) {
      console.log(`Error deleting selected rows:`, error);
    }
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
    gridRef.current = params.api;
  };

  const gridOptions = {
    // Save when blur
    stopEditingWhenCellsLoseFocus: true,
    // Pagination
    pagination: true,
    paginationPageSize: 20,
  };
  const handleDataChange = (newData) => {
    setItems(newData);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <h1>Entity Management</h1>
      <div style={{ marginBottom: "20px" }}>
        <EntityModal />
        <Button onClick={handleOpenModal}>Add Entity</Button>
        <Button variant="danger" onClick={deleteSelectedRows}>
          Delete Entity
        </Button>
        {isOpen && (
          <EntityModal
            state={isOpen}
            onClose={handleCloseModal}
            onDataChange={handleDataChange}
            currentItems={items}
          />
        )}
      </div>
      <div style={{ height: "88vh", width: "100%" }}>
        <div style={{ height: "4%", width: "100%" }}>
          <Form.Control
            type="text"
            placeholder="Search..."
            onChange={handleFilterChange}
          />
        </div>
        <div className="ag-theme-alpine" style={{ height: "96%" }}>
          <AgGridReact
            ref={gridRef}
            rowData={items}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            gridOptions={gridOptions}
            rowSelection="multiple"
            onGridReady={onGridReady}
            onCellValueChanged={onCellValueChanged}
            quickFilterText={filterText}
          />
        </div>
      </div>
    </>
  );
};

export default EntityManagement;
