import React, { useContext } from "react";
import { AuthContext, AuthProvider } from "./contexts/AuthContext";
import AppShell from "./AppShell";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import { FetchProvider } from "./contexts/FetchContext";
import PageLoadingSpinner from "./components/common/PageLoadingSpinner";
// Import page components
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import SendEDI from "./pages/SendEDI";
import EntityManagement from "./pages/EntityManagement";
import History from "./pages/History";
import FourOFour from "./pages/FourOFour";
import Settings from "./pages/Settings"
import Test from "./pages/test";

// Handles routes that require authentication.
const AuthenticatedRoute = ({ children }) => {
  const { authState } = useContext(AuthContext);
  if (!authState.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  return <AppShell>{children}</AppShell>;
};

const AdminRoute = ({children}) => {
  const {authState} = useContext(AuthContext);
  return authState.isAuthenticated && authState.userInfo.role === 'admin' ? ( 
    <AppShell>{children}</AppShell>
  ) : (
    <Navigate to="/dashboard" replace />
  );
}

const AppRoutes = () => {
  // When authState.userInfo is null, still loading...
  const { authState } = useContext(AuthContext);
  if (!authState.userInfo) {
    console.log("Still loading");
    return <PageLoadingSpinner />;
  }

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/dashboard"
        element={
          <AuthenticatedRoute>
            <Dashboard />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/send-EDI"
        element={
          <AuthenticatedRoute>
            <SendEDI />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/entity-management"
        element={
          <AuthenticatedRoute>
            <EntityManagement />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/history"
        element={
          <AuthenticatedRoute>
            <History />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <AdminRoute>
            <Settings />
          </AdminRoute>
        }
      />
      <Route
        path="/test"
        element={
          <AuthenticatedRoute>
            <Test />
          </AuthenticatedRoute>
        }
      />
      <Route path="*" element={<FourOFour />} />
    </Routes>
  );
};

function App() {
  return (
    <Router>
      <FetchProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </FetchProvider>
    </Router>
  );
}

export default App;
