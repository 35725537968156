import React, { useState, useContext } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { Button, Form as BootstrapForm } from "react-bootstrap";
import "../styles/Login.css";
import * as Yup from "yup";
import { AuthContext } from "../contexts/AuthContext";
import { FetchContext } from "../contexts/FetchContext";
import FormError from "../components/common/FormError";

const LoginSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const Login = () => {
  const authContext = useContext(AuthContext);
  const fetchContext = useContext(FetchContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [loginSuccess, setLoginSuccess] = useState(null);
  const [redirectOnLogin, setRedirectOnLogin] = useState(false);

  const handleLogin = async (credentials) => {
    try {
      setLoginLoading(true);
      const response = await fetchContext.publicAxios.post(
        "/api/users/authenticate/",
        credentials
      );
      console.log(response);
      const { data } = response;
      authContext.setAuthState(data);
      console.log("UserInfo: ", authContext.authState.userInfo);
      setLoginSuccess(data);
      setRedirectOnLogin(true);
    } catch (error) {
      setLoginLoading(false);
      console.log(error);
      const { data } = error.response;
      setLoginError(data);
      setLoginSuccess(null);
    }
  };

  return (
    <>
      {authContext.authState.isAuthenticated && <Navigate to="/dashboard" />}
      {redirectOnLogin && <Navigate to="/dashboard" />}
      <div className="auth-wrapper">
        <div className="auth-inner">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(value) => handleLogin(value)}
            validationSchema={LoginSchema}
          >
            {() => (
              <div className="register-form">
                <Form>
                  {loginError && <p>{loginError}</p>}
                  <h3 className="sign-in-title">Sign in</h3>
                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>Email address</BootstrapForm.Label>
                    <Field
                      name="email"
                      type="email"
                      placeholder="Email"
                      label="Email:"
                      className="form-control"
                    />
                  </BootstrapForm.Group>
                  <BootstrapForm.Group className="mb-3">
                    <BootstrapForm.Label>Password</BootstrapForm.Label>
                    <Field
                      name="password"
                      type="password"
                      placeholder="Password"
                      lable="Password"
                      className="form-control"
                    />
                  </BootstrapForm.Group>
                  <BootstrapForm.Group>
                    <Button type="submit" className="login-button">
                      Login
                    </Button>
                  </BootstrapForm.Group>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Login;
