import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  Table,
  Spinner,
} from "react-bootstrap";
import { FetchContext } from "../contexts/FetchContext";
import { AuthContext } from "../contexts/AuthContext";
import { fetchFormatter } from "../utils/fetchFormatter";
import FormSuccessModal from "../components/FormSuccessModal";
import FormErrorModal from "../components/FormErrorModal";

const SendEDI = () => {
  const [value, setValue] = useState("");
  const [rows, setRows] = useState("");
  const [data, setData] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formSuccess, setFormSuccess] = useState();
  const [formError, setFormError] = useState();
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [PODFile, setPODFile] = useState(null);

  const fetchContext = useContext(FetchContext);
  const authContext = useContext(AuthContext);
  const {authState} = authContext;

  const UsCurrencyRegex = /^\$?[0-9]+(\.[0-9]{1,2})?$/;
  const formRef = useRef(null);

  const handleInvoiceFileChange = (event) => {
    setInvoiceFile(event.target.files[0]);
  };

  const handlePODFileChange = (event) => {
    setPODFile(event.target.files[0]);
  };

  const handleReset = () => {
    formRef.current.reset();
    setValue("");
    setRows("");
    setFormError(false);
    setFormSuccess(false);
  };

  // Form submit handler function
  const handleSubmit = async (event) => {
    const {opusInstanceCountry} = authState.userInfo;

    event.preventDefault();
    setIsLoading(true);
    setSubmitted(true);
    const formData = new FormData(event.target);
    let data = {};
    for (const [key, value] of formData.entries()) {
      data[key] = value;
    }
    console.log(data);
    const formattedData = fetchFormatter(data, opusInstanceCountry);
    // const user = JSON.parse(localStorage.getItem("userInfo"))["name"];
    const user = authState.userInfo.name;

    const newFormData = new FormData();
    newFormData.append("data", JSON.stringify(formattedData));
    newFormData.append("user", user);
    newFormData.append("PODFile", PODFile);
    newFormData.append("invoiceFile", invoiceFile);
    try {
      const response = await fetchContext.authAxios.post(
        "api/invoices",
        newFormData
      );
      setFormSuccess(true);
      setFormError(false);
      // SetFormSuccess to false to close the success modal in 2s.
      setTimeout(() => {
        setFormSuccess(false);
      }, 2000);
    } catch (error) {
      setFormSuccess(false);
      setFormError(true);
      // SetFormError to false to close the error modal in 2s.
      setTimeout(() => {
        setFormError(false);
      }, 2000);
    }
    setIsLoading(false);
  };

  // Charge Table Update Handler
  const handleChange = (event) => {
    handleFormInputChange(event);
    const input = event.target.value.slice(0, -1); // Remove last "\n"
    setValue(input);
    setRows(input.split("\n"));
  };

  useEffect(() => {
    const getEntityList = async () => {
      try {
        const { data } = await fetchContext.authAxios("/api/entities");
        setData(data);
      } catch (error) {
        console.log(error);
      }
    };
    getEntityList();
  }, []);

  const handleFormInputChange = (event) => {
    const { name: inputName, value: inputValue } = event.target;
    setFormValues((preValue) => ({ ...preValue, [inputName]: inputValue }));
  };

  useEffect(() => {
    console.log(formValues);
  }, [formValues]);

  // Rendering part starts from here
  return (
    <>
      <h1 style={{ textAlign: "center" }}>Send EDI</h1>
      <div>
        {formSuccess && (
          <FormSuccessModal
            text="EDI Successfully Sent"
            formSuccess={formSuccess}
          />
        )}
        {formError && (
          <FormErrorModal
            text="Fail to send an EDI file"
            formError={formError}
          />
        )}
      </div>
      <div style={{ width: "80%", margin: "auto" }}>
        <Form
          onSubmit={handleSubmit}
          ref={formRef}
          encType="multipart/form-data"
        >
          <Row className="mb-3">
            <Form.Group className="col-md-2" as={Col} controlId="invoiceNo">
              <Form.Label>Invoice No.</Form.Label>
              <Form.Control
                type="text"
                name="invoiceNo"
                placeholder="Invoice No."
                required
                onChange={handleFormInputChange}
                isInvalid={!formValues.invoiceNo}
              />
              <Form.Control.Feedback type="invalid">
                Enter Invoice Number
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-md-2" as={Col} controlId="invoiceType">
              <Form.Label>Invoice Type</Form.Label>
              <Form.Select
                name="invoiceType"
                placeholder="Invoice Type"
                required
                onChange={handleFormInputChange}
                isInvalid={
                  !formValues.invoiceType || formValues.invoiceType === ""
                }
              >
                <option value="">--Select Invoice Type--</option>
                <option value="original">Original</option>
                <option value="cancellation">Cancellation</option>
                <option value="change">Change</option>
                <option value="replacement">Replacement</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Select Invoice Type
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-md-2" as={Col} controlId="netAmount">
              <Form.Label>Net Amount</Form.Label>
              <Form.Control
                type="text"
                name="netAmount"
                placeholder="Net Amount"
                required
                onChange={handleFormInputChange}
                isInvalid={
                  !formValues.netAmount ||
                  !UsCurrencyRegex.test(formValues.netAmount)
                }
              />
              <Form.Control.Feedback type="invalid">
                Enter Net Amount
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="col-md-2" as={Col} controlId="mblNo">
              <Form.Label>MBL No.</Form.Label>
              <Form.Control
                type="text"
                name="mblNo"
                placeholder="MBL No"
                required
                onChange={handleFormInputChange}
                isInvalid={!formValues.mblNo || formValues.mblNo === ""}
              />
              <Form.Control.Feedback type="invalid">
                Enter MBL No.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-md-2" as={Col} controlId="poNo">
              <Form.Label>PO No.</Form.Label>
              <Form.Control
                type="text"
                name="poNos"
                placeholder="PO No."
                required
                onChange={handleFormInputChange}
                isInvalid={!formValues.poNos || formValues.poNos === ""}
              />
              <Form.Control.Feedback type="invalid">
                Enter PO Nos.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-md-2" as={Col} controlId="serviceType">
              <Form.Label>Service Type</Form.Label>
              <Form.Select
                name="serviceType"
                required
                onChange={handleFormInputChange}
                isInvalid={
                  !formValues.serviceType || formValues.serviceType === ""
                }
              >
                <option value="">--Select Invoice Type--</option>
                <option value="fclfcl">FCL/FCL</option>
                <option value="fcllcl">FCL/LCL</option>
                <option value="lcllcl">LCL/LCL</option>
                <option value="lclfcl">LCL/FCL</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Select Service Type
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="col-md-2" as={Col} controlId="carrierSCAC">
              <Form.Label>Carrier SCAC </Form.Label>
              <Form.Control
                type="text"
                name="carrierSCAC"
                placeholder="Carrier SCAC"
                required
                onChange={handleFormInputChange}
                isInvalid={
                  !formValues.carrierSCAC || formValues.carrierSCAC === ""
                }
              />
              <Form.Control.Feedback type="invalid">
                Enter Carrier SCAC
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-md-2" as={Col} controlId="vesselName">
              <Form.Label>Vessel Name</Form.Label>
              <Form.Control
                type="text"
                name="vesselName"
                required
                placeholder="Vessel Name"
                onChange={handleFormInputChange}
                isInvalid={
                  !formValues.vesselName || formValues.vesselName === ""
                }
              />
              <Form.Control.Feedback type="invalid">
                Enter Vessel Name
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-md-2" as={Col} controlId="vesselCode">
              <Form.Label>Vessel Code</Form.Label>
              <Form.Control
                type="text"
                name="vesselCode"
                placeholder="Vessel Code"
                required
                onChange={handleFormInputChange}
                isInvalid={
                  !formValues.vesselCode || formValues.vesselCode === ""
                }
              />
              <Form.Control.Feedback type="invalid">
                Enter Vessel Code
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="col-md-2" as={Col} controlId="equipmentType">
              <Form.Label>Equipment Type</Form.Label>
              <Form.Select
                name="equipmentType"
                onChange={handleFormInputChange}
                required
                isInvalid={
                  !formValues.equipmentType || formValues.equipmentType === ""
                }
              >
                <option value="">--Select Equipment Type--</option>
                <option value="FCL">FCL</option>
                <option value="LCL">LCL</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Select Equipment Type
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-md-2" as={Col} controlId="containerNo">
              <Form.Label>Container No.</Form.Label>
              <Form.Control
                type="text"
                name="containerNo"
                required
                placeholder="Container No."
                onChange={handleFormInputChange}
                isInvalid={
                  !formValues.containerNo || formValues.containerNo === ""
                }
              />
              <Form.Control.Feedback type="invalid">
                Enter Container No
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-md-2" as={Col} controlId="containerType">
              <Form.Label>Container Type</Form.Label>
              <Form.Control
                type="text"
                name="containerType"
                placeholder="Container Type"
                required
                onChange={handleFormInputChange}
                isInvalid={
                  !formValues.containerType || formValues.containerType === ""
                }
              />
              <Form.Control.Feedback type="invalid">
                Enter Container Type
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="col-md-2" as={Col} controlId="weight">
              <Form.Label>Weight</Form.Label>
              <Form.Control
                type="text"
                required
                name="weight"
                placeholder="weight"
                onChange={handleFormInputChange}
                isInvalid={!formValues.weight || formValues.weight === ""}
              />
              <Form.Control.Feedback type="invalid">
                Enter Weight
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-md-2" as={Col} controlId="volume">
              <Form.Label>Volume</Form.Label>
              <Form.Control
                type="test"
                name="volume"
                required
                placeholder="Volume"
                onChange={handleFormInputChange}
                isInvalid={!formValues.volume || formValues.volume === ""}
              />
              <Form.Control.Feedback type="invalid">
                Enter Volume
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="col-md-3"
              as={Col}
              controlId="ladingQuantity"
            >
              <Form.Label>Lading Quantity</Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  type="text"
                  name="ladingQuantity"
                  required
                  placeholder="Lading Quantity"
                  onChange={handleFormInputChange}
                  isInvalid={
                    !formValues.ladingQuantity ||
                    formValues.ladingQuantity === ""
                  }
                />
                <Form.Select
                  style={{ maxWidth: "200px" }}
                  name="packingForm"
                  required
                  onChange={handleFormInputChange}
                  isInvalid={
                    !formValues.packingForm || formValues.packingForm === ""
                  }
                >
                  <option value="">--Select Packing Form--</option>
                  <option value="bundle">Bundle</option>
                  <option value="box">Box</option>
                  <option value="case">Case</option>
                  <option value="carton">Carton</option>
                  <option value="pieces">Pieces</option>
                  <option value="pallet">Pallet</option>
                  <option value="roll">Roll</option>
                  <option value="skid">Skid</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Enter Lading Info
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="shipperOrigin">
              <Form.Label>Shipper / Origin</Form.Label>
              <Form.Select
                name="shipperOrigin"
                required
                onChange={handleFormInputChange}
                isInvalid={
                  !formValues.shipperOrigin || formValues.shipperOrigin === ""
                }
              >
                <option value="">--Select an entity--</option>
                {data.map((entity, index) => (
                  <option
                    key={index}
                    value={JSON.stringify({
                      name: entity.name,
                      address: entity.address,
                      city: entity.city,
                      state: entity.state,
                      country: entity.country,
                      postalCode: entity.postalCode,
                    })}
                  >
                    {entity.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Enter Shipper / Origin
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="shipTo">
              <Form.Label>Ship To</Form.Label>
              <Form.Select
                name="shipTo"
                required
                onChange={handleFormInputChange}
                isInvalid={!formValues.shipTo || formValues.shipTo === ""}
              >
                <option value="">--Select an entity--</option>
                {data.map((entity, index) => (
                  <option
                    key={index}
                    value={JSON.stringify({
                      name: entity.name,
                      address: entity.address,
                      city: entity.city,
                      state: entity.state,
                      country: entity.country,
                      postalCode: entity.postalCode,
                    })}
                  >
                    {entity.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Enter Ship To
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="consignee">
              <Form.Label>Consignee</Form.Label>
              <Form.Select
                name="consignee"
                required
                onChange={handleFormInputChange}
                isInvalid={!formValues.consignee || formValues.consignee === ""}
              >
                <option value="">--Select an entity--</option>
                {data.map((entity, index) => (
                  <option
                    key={index}
                    value={JSON.stringify({
                      name: entity.name,
                      address: entity.address,
                      city: entity.city,
                      state: entity.state,
                      country: entity.country,
                      postalCode: entity.postalCode,
                    })}
                  >
                    {entity.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Enter Consignee
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Form.Group as={Col} controlId="portOfDischarge">
              <Form.Label>Port of Discharge</Form.Label>
              <Form.Control
                type="text"
                required
                name="portOfDischargeName"
                placeholder="Port of Discharge"
                onChange={handleFormInputChange}
                isInvalid={
                  !formValues.portOfDischargeName ||
                  formValues.portOfDischargeName === ""
                }
              />
              <Form.Control
                type="date"
                required
                name="portOfDischargeDate"
                placeholder="Port of Discharge Date"
                onChange={handleFormInputChange}
                isInvalid={
                  !formValues.portOfDischargeDate ||
                  formValues.portOfDischargeDate === ""
                }
              />
              <Form.Control.Feedback type="invalid">
                Enter Port of Discharge
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="placeOfDelivery">
              <Form.Label>Place of Delivery</Form.Label>
              <Form.Control
                type="text"
                required
                name="placeOfDeliveryName"
                placeholder="Place of Delivery"
                onChange={handleFormInputChange}
                isInvalid={
                  !formValues.placeOfDeliveryName ||
                  formValues.placeOfDeliveryName === ""
                }
              />
              <Form.Control
                type="date"
                required
                name="placeOfDeliveryDate"
                placeholder="Place of Delivery Date"
                onChange={handleFormInputChange}
                isInvalid={
                  !formValues.placeOfDeliveryDate ||
                  formValues.placeOfDeliveryDate === ""
                }
              />
              <Form.Control.Feedback type="invalid">
                Enter Place of Delivery
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="portOfServicing">
              <Form.Label>Port of Servicing</Form.Label>
              <Form.Control
                type="text"
                required
                name="portOfServicingName"
                placeholder="Port of Servicing"
                onChange={handleFormInputChange}
                isInvalid={
                  !formValues.portOfServicingName ||
                  formValues.portOfServicingName === ""
                }
              />
              <Form.Control
                type="date"
                required
                name="portOfServicingDate"
                placeholder="Port of Servicing Date"
                onChange={handleFormInputChange}
                isInvalid={
                  !formValues.portOfServicingDate ||
                  formValues.portOfServicingDate === ""
                }
              />
              <Form.Control.Feedback type="invalid">
                Enter Port of Servicing
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="placeOfReceipt">
              <Form.Label>Place of Receipt</Form.Label>
              <Form.Control
                type="text"
                name="placeOfReceiptName"
                placeholder="Place of Receipt"
                onChange={handleFormInputChange}
              />
              <Form.Control
                type="date"
                name="placeOfReceiptDate"
                placeholder="Place of Receipt Date"
                onChange={handleFormInputChange}
              />
            </Form.Group>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Form.Group as={Col}>
              <Form.Label>Invoice Document</Form.Label>
              <Form.Control
                type="file"
                id="invoiceFile"
                name="invoiceFile"
                onChange={handleInvoiceFileChange}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>POD Document</Form.Label>
              <Form.Control
                type="file"
                id="podFile"
                name="podFile"
                onChange={handlePODFileChange}
              />
            </Form.Group>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Form.Group>
              <Form.Label>Charges</Form.Label>
              {value && (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Freight Code</th>
                      <th>Freight Name</th>
                      <th>P/C</th>
                      <th>Unit</th>
                      <th>TP/SZ</th>
                      <th>Rate</th>
                      <th>Volume</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  { authState.userInfo.opusInstanceCountry === "US" &&
                    <tbody>
                      {rows.map((row, index) => (
                        <tr key={`row-${index}`}>
                          {row.split("\t").map((cell, cellIndex) => (
                            <td key={`cell-${index}-${cellIndex}`}>{cell}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  }
                  {
                    authState.userInfo.opusInstanceCountry === "MX" && 
                    <tbody>
                      {rows.map((row, index) => (
                        <tr key={`row-${index}`}>
                          {row.split("\t").map((cell, cellIndex) => (
                            ([5, 6, 7, 8, 12, 13, 14, 15].includes(cellIndex)) ? null : (
                              <td key={`cell-${index}-${cellIndex}`}>{cell}</td>
                            )
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  }
                </Table>
              )}
              <Form.Control
                as="textarea"
                name="charges"
                required
                onChange={handleChange}
                rows={12}
                isInvalid={!formValues.charges || formValues.charges === ""}
              />
              <Form.Control.Feedback type="invalid">
                Enter Charges
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <div className="text-center" style={{ marginBottom: "20px" }}>
              <Button
                variant="primary"
                style={{
                  marginTop: "20px",
                  width: "50%",
                  margin: "5px",
                }}
                type="submit"
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Send EDI"
                )}
              </Button>
              <Button
                variant="secondary"
                style={{
                  marginTop: "20px",
                  width: "15%",
                  margin: "5px",
                }}
                onClick={handleReset}
              >
                Reset Form
              </Button>
            </div>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default SendEDI;
