import React, { useEffect, useState, useMemo, useRef, useContext, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { Form } from "react-bootstrap";
import { FetchContext } from "../contexts/FetchContext";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

// Converts cents to USD
const centsToUSD = ({value}) => {
  const netAmount = value;
  const formattedNetAmount = `$${(netAmount / 100).toFixed(2)}`;
  return formattedNetAmount;
};

// Acceptance column formatter
const acceptanceValueformatter = ({value}) => {

  // If the value is empty (Old records without 997 information) return "N/A"
  // Otherwise, show the value of the cell
  if (!value) {
    return "N/A"
  }
  return value
}

// Convert UTC time to local time
const convertUTCToLocal = ({value}) => {
  // Remove the milliseconds from the UTC date string
  const utcDateWithoutMillis = value.slice(0, -5) + "Z";

  // Create a new Date object from the UTC date string
  const utcDate = new Date(utcDateWithoutMillis);

  // Get the offset between UTC and local time in minutes
  const offsetMinutes = utcDate.getTimezoneOffset();

  // Add the offset to the UTC time to get the local time
  const localTime = new Date(utcDate.getTime());

  // Get the components of the local time
  const year = localTime.getFullYear();
  const month = String(localTime.getMonth() + 1).padStart(2, "0");
  const day = String(localTime.getDate()).padStart(2, "0");
  const hours = String(localTime.getHours()).padStart(2, "0");
  const minutes = String(localTime.getMinutes()).padStart(2, "0");

  // Construct the formatted date string
  const formattedTime = `${year}-${month}-${day} / ${hours}:${minutes}`;

  return formattedTime;

}

const History = () => {
  const [items, setItems] = useState([]);
  const [filterText, setFilterText] = useState("");
  const fetchContext = useContext(FetchContext);
  const gridRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchContext.authAxios("/api/invoices");
      setItems(data);
    };
    fetchData();
  }, []);

  const columnDefs = [
    { field: "invoiceNo" },
    { field: "invoiceType" },
    { field: "mblNo", headerName: "MBL No" },
    { field: "containerNo" },
    { field: "netAmount", valueFormatter: centsToUSD },
    { field: "user" },
    { field: "createdDate", headerName: "Date / Time", valueFormatter: convertUTCToLocal },
    { field: "status" },
    { field: "acceptance", headerName: "Acceptance", valueFormatter: acceptanceValueformatter },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
  };

  const handleFilterChange = useCallback((event) => {
    setFilterText(event.target.value);
  }, []);

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit();
  }, []);

  const gridOptions = {
    // Pagination
    pagination: true,
    paginationPageSize: 20,
  };

  return (
    <>
      <h1>EDI History</h1>
      <div style={{ height: "88vh", width: "100vw" }}>
        <div style={{ height: "4%", width: "100%" }}>
          <Form.Control
            type="text"
            placeholder="Search..."
            onChange={(event) => handleFilterChange(event)}
          />
        </div>
        <div className="ag-theme-alpine" style={{ height: "96%" }}>
          <AgGridReact
            ref={gridRef}
            rowData={items}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            gridOptions={gridOptions}
            rowSelection="multiple"
            onGridReady={onGridReady}
            quickFilterText={filterText}
          />
        </div>
      </div>
    </>
  );
};

export default History;
