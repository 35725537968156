import React, {useContext} from "react";
import { AuthContext } from "../contexts/AuthContext";
// import Navbar from "../components/Navbar";
// import styles from "../styles/Dashboard.module.css";

const Dashboard = () => {
  const auth = useContext(AuthContext);
  const {authState} = auth;
  console.log(authState.userInfo.name)
  
  return (
    <div>
      <h1>Dashboard</h1>
    </div>
  );
};

export default Dashboard;
