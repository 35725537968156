import React, { useEffect, useState } from "react";
import { Modal, Alert } from "react-bootstrap";

const FormErrorModal = ({ text, formError }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (formError) {
      setShow(true);
    }
  }, [formError]);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        style={{
          zIndex: 1060,
          position: "fixed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "10px auto 0",
        }}
      >
        <Alert
          variant="danger"
          dismissible
          style={{
            width: "100%",
            margin: "auto",
          }}
        >
          <Alert.Heading>Failed</Alert.Heading>
          <p>{text}</p>
        </Alert>
      </Modal>
    </>
  );
};

export default FormErrorModal;
