import React from "react";
import Navgationbar from "./components/Navbar";

const AppShell = ({ children }) => {
  return (
    <>
      <div>
        <Navgationbar />
        <div>{children}</div>
      </div>
    </>
  );
};

export default AppShell;
