import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const PageLoadingSpinner = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ClipLoader size={150} loading={true} />
      </div>
    </>
  );
};

export default PageLoadingSpinner;
